import { __awaiter, __generator } from "tslib";
import { getComponent, } from '@wix/platform-editor-helpers';
import { ComponentType } from './types';
import { getSearchResultsHeaderComponentDefinition } from './componentDefinitions';
import { addBreadcrumb } from './sentry';
export function getSearchResultsComponent(editor) {
    return __awaiter(this, void 0, Promise, function () {
        var result;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    addBreadcrumb('getSearchResultsComponent: start');
                    return [4 /*yield*/, getComponent(editor, function (_a) {
                            var componentType = _a.componentType, componentData = _a.componentData;
                            return componentData &&
                                componentData.appDefinitionId === editor.appDefinitionId &&
                                componentType === ComponentType.TPA;
                        })];
                case 1:
                    result = _a.sent();
                    if (!result) {
                        addBreadcrumb('getSearchResultsComponent result is empty');
                    }
                    addBreadcrumb('getSearchResultsComponent: finish');
                    return [2 /*return*/, result];
            }
        });
    });
}
export function getSearchResultsPage(editor) {
    return __awaiter(this, void 0, Promise, function () {
        var result, _a, _b, _c;
        var _d;
        return __generator(this, function (_e) {
            switch (_e.label) {
                case 0:
                    addBreadcrumb('getSearchResultsPage: start');
                    _b = (_a = editor.SDK.components).getPage;
                    _c = [editor.token];
                    _d = {};
                    return [4 /*yield*/, getSearchResultsComponent(editor)];
                case 1: return [4 /*yield*/, _b.apply(_a, _c.concat([(_d.componentRef = _e.sent(),
                            _d)]))];
                case 2:
                    result = _e.sent();
                    addBreadcrumb('getSearchResultsPage: finish');
                    return [2 /*return*/, result];
            }
        });
    });
}
// NOTE: search results layout https://zpl.io/VOxkPyY
// https://docs.google.com/presentation/d/1cxgiYIkRgOVdy-xUv090CfDSdapppE9-IvGejs2qHhE/edit#slide=id.g5f497222a2_4_39
var SEARCH_RESULTS_HEADER_LAYOUT = {
    width: 600,
    height: 43,
    marginTop: 52,
    marginBottom: 52,
};
var SEARCH_RESULTS_LAYOUT = {
    marginTop: 16,
};
export function addSearchResultsHeader(editor, _a) {
    var searchResultsPageRef = _a.searchResultsPageRef, searchResultsRef = _a.searchResultsRef;
    return __awaiter(this, void 0, Promise, function () {
        var searchResultsPageLayout, headerLayout, headerMarginBottomFixed, result;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    addBreadcrumb('addSearchResultsHeader: start');
                    return [4 /*yield*/, editor.SDK.components.layout.get(editor.token, {
                            componentRef: searchResultsPageRef,
                        })];
                case 1:
                    searchResultsPageLayout = _b.sent();
                    headerLayout = {
                        x: Math.round(searchResultsPageLayout.bounding.width / 2 -
                            SEARCH_RESULTS_HEADER_LAYOUT.width / 2),
                        y: SEARCH_RESULTS_HEADER_LAYOUT.marginTop,
                        width: SEARCH_RESULTS_HEADER_LAYOUT.width,
                        height: SEARCH_RESULTS_HEADER_LAYOUT.height,
                    };
                    headerMarginBottomFixed = SEARCH_RESULTS_HEADER_LAYOUT.marginBottom -
                        SEARCH_RESULTS_LAYOUT.marginTop -
                        9;
                    return [4 /*yield*/, editor.SDK.components.layout.update(editor.token, {
                            componentRef: searchResultsRef,
                            layout: {
                                x: 0,
                                y: headerLayout.y + headerLayout.height + headerMarginBottomFixed,
                            },
                        })];
                case 2:
                    _b.sent();
                    return [4 /*yield*/, editor.SDK.components.add(editor.token, {
                            pageRef: searchResultsPageRef,
                            componentDefinition: getSearchResultsHeaderComponentDefinition({
                                // TODO: use translation for this text
                                text: 'Search Results',
                                layout: headerLayout,
                            }),
                        })];
                case 3:
                    result = _b.sent();
                    addBreadcrumb('addSearchResultsHeader: finish');
                    return [2 /*return*/, result];
            }
        });
    });
}
export function updateSearchResultsPageData(editor, _a) {
    var searchResultsPageRef = _a.searchResultsPageRef;
    return __awaiter(this, void 0, Promise, function () {
        var result;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    addBreadcrumb('updateSearchResultsPageData: start');
                    return [4 /*yield*/, editor.SDK.pages.data.update(editor.token, {
                            pageRef: searchResultsPageRef,
                            // interface - https://github.com/wix-private/js-platform-editor-sdk/blob/35499d000316ac4b4921121ee4a6c810589c6387/js/common.ts#L133
                            data: {
                                hidePage: true,
                                indexable: false,
                            },
                        })];
                case 1:
                    result = _b.sent();
                    addBreadcrumb('updateSearchResultsPageData: finish');
                    return [2 /*return*/, result];
            }
        });
    });
}
