import { __awaiter, __generator } from "tslib";
import { getControllerConnectedComponents, } from '@wix/platform-editor-helpers';
import { getSearchAppControllers } from '../searchAppController';
import { isSearchBox } from '../searchBox';
import { addBreadcrumb } from '../sentry';
export function getConnectedSearchBoxes(editor) {
    return __awaiter(this, void 0, void 0, function () {
        var controllerRefs, connectedSearchBoxes, _i, controllerRefs_1, controllerRef, connectedComponentsRefs, _a, connectedComponentsRefs_1, componentRef;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    addBreadcrumb('getConnectedSearchBoxes: start');
                    return [4 /*yield*/, getSearchAppControllers(editor)];
                case 1:
                    controllerRefs = _b.sent();
                    connectedSearchBoxes = [];
                    _i = 0, controllerRefs_1 = controllerRefs;
                    _b.label = 2;
                case 2:
                    if (!(_i < controllerRefs_1.length)) return [3 /*break*/, 8];
                    controllerRef = controllerRefs_1[_i];
                    return [4 /*yield*/, getControllerConnectedComponents(editor, controllerRef)];
                case 3:
                    connectedComponentsRefs = _b.sent();
                    _a = 0, connectedComponentsRefs_1 = connectedComponentsRefs;
                    _b.label = 4;
                case 4:
                    if (!(_a < connectedComponentsRefs_1.length)) return [3 /*break*/, 7];
                    componentRef = connectedComponentsRefs_1[_a];
                    return [4 /*yield*/, isSearchBox(editor, componentRef)];
                case 5:
                    if (_b.sent()) {
                        connectedSearchBoxes.push(componentRef);
                    }
                    _b.label = 6;
                case 6:
                    _a++;
                    return [3 /*break*/, 4];
                case 7:
                    _i++;
                    return [3 /*break*/, 2];
                case 8:
                    addBreadcrumb('getConnectedSearchBoxes: finish');
                    return [2 /*return*/, connectedSearchBoxes];
            }
        });
    });
}
