import { __assign, __awaiter, __generator, __spreadArray } from "tslib";
import { getSearchResults } from '@wix/client-search-sdk/dist/src/sdk/mockApi/searchResults';
import { SuggestionsParser } from '@wix/client-search-sdk/dist/src/sdk/parsers';
import { SearchDocumentType, } from '@wix/client-search-sdk';
import { addBreadcrumb } from './sentry';
export function setDemoSuggestions(editor, componentRef) {
    return __awaiter(this, void 0, void 0, function () {
        var parser, results, documents, suggestions;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    addBreadcrumb('setDemoSuggestions: start');
                    parser = new SuggestionsParser('', 'en');
                    results = __spreadArray(__spreadArray([], getSearchResults({
                        query: '',
                        documentType: SearchDocumentType.Pages,
                        limit: 3,
                        skip: 0,
                    })), getSearchResults({
                        query: '',
                        documentType: SearchDocumentType.Products,
                        limit: 3,
                        skip: 0,
                    }));
                    documents = parser.parse(results);
                    suggestions = {
                        query: 'search term...',
                        searchAllUrl: '/search-term',
                        documents: documents.map(function (document) {
                            return __assign(__assign({}, document), { url: '/document-type' });
                        }),
                    };
                    return [4 /*yield*/, editor.SDK.components.data.update(editor.token, {
                            componentRef: componentRef,
                            data: {
                                suggestions: suggestions,
                            },
                        })];
                case 1:
                    _a.sent();
                    addBreadcrumb('setDemoSuggestions: finish');
                    return [2 /*return*/];
            }
        });
    });
}
