import { __assign, __awaiter, __generator } from "tslib";
import { addBreadcrumb } from '../sentry';
export function patchInputFontProperty(editor, componentRef) {
    return __awaiter(this, void 0, void 0, function () {
        var old, needsInputFont;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    addBreadcrumb('patchInputFontProperty: start');
                    return [4 /*yield*/, editor.SDK.components.style.get(editor.token, {
                            componentRef: componentRef,
                        })];
                case 1:
                    old = _a.sent();
                    needsInputFont = old && old.style && old.style.properties && !old.style.properties.inputFont;
                    if (!needsInputFont) {
                        return [2 /*return*/];
                    }
                    return [4 /*yield*/, editor.SDK.components.style.updateFull(editor.token, {
                            componentRef: componentRef,
                            style: __assign(__assign({}, old), { style: __assign(__assign({}, old.style), { properties: __assign(__assign({}, old.style.properties), { inputFont: 'font_8' }), propertiesSource: __assign(__assign({}, old.style.propertiesSource), { inputFont: 'value' }) }) }),
                        })];
                case 2:
                    _a.sent();
                    addBreadcrumb('patchInputFontProperty: finish');
                    return [2 /*return*/];
            }
        });
    });
}
