import { ComponentType, ControllerType, } from './types';
import { addBreadcrumb } from './sentry';
import { isAppWidgetConnectionPrimary } from './experiments';
export function getAppWidgetWrappedSearchBoxDefinition(_a, context) {
    var layout = _a.layout, appDefinitionId = _a.appDefinitionId;
    addBreadcrumb('getAppWidgetWrappedSearchBoxDefinition');
    // the add component flow expects the connections to be defined, and
    // it knows to replace the ids with the real ids its going to put there
    var controllerId = 'placeHolderId';
    return {
        componentType: 'platform.components.AppWidget',
        components: [
            {
                componentType: ComponentType.SearchBox,
                data: {
                    type: 'SearchBox',
                },
                props: {
                    type: 'SearchBoxProperties',
                },
                styleId: 'SearchBox_1',
                skin: 'wixui.skins.SearchBox',
                layout: layout,
                style: {
                    skin: 'wixui.skins.SearchBox',
                    componentClassName: 'wixui.SearchBox',
                    style: {
                        properties: {
                            'alpha-inputIconColor': '0.6',
                            'alpha-inputBorderColor': '0.6',
                            'alpha-buttonBackgroundColor': '0.8',
                            'boxShadowToggleOn-inputShadow': 'false',
                            inputShadow: '0px 1px 4px 0px rgba(0, 0, 0, 0.6)',
                            inputFont: 'font_8',
                        },
                        propertiesSource: {
                            'alpha-inputIconColor': 'value',
                            'alpha-inputBorderColor': 'value',
                            'alpha-buttonBackgroundColor': 'value',
                            'boxShadowToggleOn-inputShadow': 'value',
                            inputShadow: 'value',
                            inputFont: 'value',
                        },
                    },
                },
                connections: {
                    type: 'ConnectionList',
                    items: [
                        {
                            type: 'ConnectionItem',
                            role: 'searchBox',
                            controllerId: controllerId,
                            isPrimary: isAppWidgetConnectionPrimary(context.experiments),
                        },
                    ],
                },
            },
        ],
        layout: layout,
        data: {
            type: 'AppController',
            controllerType: ControllerType.SearchApp,
            applicationId: appDefinitionId,
            settings: JSON.stringify({}),
            id: controllerId,
        },
    };
}
export function getSearchBoxComponentDefinition(_a) {
    var layout = _a.layout;
    addBreadcrumb('getSearchBoxComponentDefinition');
    return {
        componentType: ComponentType.SearchBox,
        layout: layout,
        data: {
            type: 'SearchBox',
        },
        props: {
            type: 'SearchBoxProperties',
        },
        styleId: 'SearchBox_1',
        skin: 'wixui.skins.SearchBox',
        style: {
            skin: 'wixui.skins.SearchBox',
            componentClassName: 'wixui.SearchBox',
            style: {
                properties: {
                    'alpha-inputIconColor': '0.6',
                    'alpha-inputBorderColor': '0.6',
                    'alpha-buttonBackgroundColor': '0.8',
                    'boxShadowToggleOn-inputShadow': 'false',
                    inputShadow: '0px 1px 4px 0px rgba(0, 0, 0, 0.6)',
                    inputFont: 'font_8',
                },
                propertiesSource: {
                    'alpha-inputIconColor': 'value',
                    'alpha-inputBorderColor': 'value',
                    'alpha-buttonBackgroundColor': 'value',
                    'boxShadowToggleOn-inputShadow': 'value',
                    inputShadow: 'value',
                    inputFont: 'value',
                },
            },
        },
    };
}
export function getSearchResultsHeaderComponentDefinition(_a) {
    var text = _a.text, layout = _a.layout;
    addBreadcrumb('getSearchResultsHeaderComponentDefinition');
    return {
        componentType: 'wysiwyg.viewer.components.WRichText',
        skin: 'wysiwyg.viewer.skins.WRichTextNewSkin',
        layout: layout,
        data: {
            text: "<h1 class=\"font_0\" style=\"font-size:32px;text-align:center;\"><span style=\"font-size:32px;\">" + text + "</span></h1>",
            type: 'StyledText',
            stylesMapId: 'CK_EDITOR_PARAGRAPH_STYLES',
        },
    };
}
export function getSearchAppControllerComponentDefinition(_a) {
    var appDefinitionId = _a.appDefinitionId;
    addBreadcrumb('getSearchAppControllerComponentDefinition');
    return {
        componentType: 'platform.components.AppController',
        data: {
            type: 'AppController',
            controllerType: ControllerType.SearchApp,
            applicationId: appDefinitionId,
            settings: JSON.stringify({}),
        },
        layout: {
            fixedPosition: true,
            docked: {
                bottom: {
                    px: 20,
                },
                hCenter: {
                    px: 0,
                },
            },
        },
    };
}
