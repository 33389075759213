import { __awaiter, __generator } from "tslib";
import { addSearchAppController, connectControllerWithSearchBox, connectControllerWithSearchResults, } from '../searchAppController';
import { addSearchResultsHeader, getSearchResultsComponent, getSearchResultsPage, updateSearchResultsPageData, } from '../searchResults';
import { addSearchBox } from '../searchBox';
import { addBreadcrumb } from '../sentry';
import { fedopsLogger, Interactions } from '../fedopsLogger';
import { isWrappingWithAppWidgetEnabled } from '../experiments';
export function doFirstInstall(editor) {
    return __awaiter(this, void 0, void 0, function () {
        var searchBoxRef, _a, searchResultsRef, searchResultsPageRef, searchAppControllerRef, promises;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    addBreadcrumb('doFirstInstall: start');
                    fedopsLogger.interactionStarted(Interactions.InstallApp);
                    return [4 /*yield*/, addSearchBox(editor)];
                case 1:
                    searchBoxRef = _b.sent();
                    return [4 /*yield*/, Promise.all([getSearchResultsComponent(editor), getSearchResultsPage(editor)])];
                case 2:
                    _a = _b.sent(), searchResultsRef = _a[0], searchResultsPageRef = _a[1];
                    if (!searchResultsRef) return [3 /*break*/, 4];
                    return [4 /*yield*/, addSearchResultsHeader(editor, {
                            searchResultsPageRef: searchResultsPageRef,
                            searchResultsRef: searchResultsRef,
                        })];
                case 3:
                    _b.sent();
                    _b.label = 4;
                case 4: return [4 /*yield*/, updateSearchResultsPageData(editor, {
                        searchResultsPageRef: searchResultsPageRef,
                    })];
                case 5:
                    _b.sent();
                    return [4 /*yield*/, addSearchAppController(editor)];
                case 6:
                    searchAppControllerRef = _b.sent();
                    promises = [];
                    if (!isWrappingWithAppWidgetEnabled(editor.experiments)) {
                        promises.push(connectControllerWithSearchBox(editor, searchAppControllerRef, searchBoxRef));
                    }
                    if (searchResultsRef) {
                        promises.push(connectControllerWithSearchResults(editor, searchAppControllerRef, searchResultsRef));
                    }
                    return [4 /*yield*/, Promise.all(promises)];
                case 7:
                    _b.sent();
                    return [4 /*yield*/, editor.SDK.pages.navigateTo(editor.token, {
                            pageRef: searchResultsPageRef,
                        })];
                case 8:
                    _b.sent();
                    return [4 /*yield*/, editor.SDK.selection.selectComponentByCompRef(editor.token, {
                            compsToSelect: [searchBoxRef],
                        })];
                case 9:
                    _b.sent();
                    fedopsLogger.interactionEnded(Interactions.InstallApp);
                    addBreadcrumb('doFirstInstall: finish');
                    return [2 /*return*/];
            }
        });
    });
}
