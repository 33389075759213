import { __awaiter, __generator } from "tslib";
import * as Sentry from '@sentry/browser';
import { setSentryContext, withErrorReporting, addBreadcrumb, initErrorLogging, } from './sentry';
import { getAllSearchBoxes } from './searchBox';
import { ControllerType, EventType } from './types';
import { createAppApi } from './appApi';
import { doFirstInstall } from './doFirstInstall';
import { onComponentAddedToStage } from './onComponentAddedToStage';
import { fedopsLogger, Interactions } from './fedopsLogger';
import { patchInputFontProperty } from './patchInputFontProperty';
import { onRemoveApp } from './onRemoveApp';
import { reconnectSearchBoxes } from './reconnectSearchBoxes';
import { getIsInEditorX } from './isInEditorX';
import { concurrentEditingEnabled, fetchExperiments } from './experiments';
initErrorLogging();
addBreadcrumb('initSearchPlatform: start');
fedopsLogger.appLoadStarted();
var editor;
var fetchExperimentsPromise = fetchExperiments();
export var editorReady = withErrorReporting(function editorReadyHandler(SDK, appDefinitionId, payload) {
    return __awaiter(this, void 0, void 0, function () {
        var experiments, ex_1, ex_2, allSearchBoxes;
        var _this = this;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    addBreadcrumb('editorReadyHandler: start');
                    fedopsLogger.interactionStarted(Interactions.EditorReady);
                    setSentryContext(payload);
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, fetchExperimentsPromise];
                case 2:
                    experiments = _a.sent();
                    return [3 /*break*/, 4];
                case 3:
                    ex_1 = _a.sent();
                    Sentry.captureException(ex_1);
                    return [3 /*break*/, 4];
                case 4:
                    editor = {
                        SDK: SDK,
                        appDefinitionId: appDefinitionId,
                        // NOTE: https://wix.slack.com/archives/C4KPAQ33K/p1564490148055400
                        token: appDefinitionId,
                        experiments: experiments,
                    };
                    SDK.editor.setAppAPI('', createAppApi());
                    if (!payload.firstInstall) return [3 /*break*/, 11];
                    if (!concurrentEditingEnabled(experiments)) return [3 /*break*/, 9];
                    _a.label = 5;
                case 5:
                    _a.trys.push([5, 7, , 8]);
                    return [4 /*yield*/, SDK.document.transactions.runAndWaitForApproval(editor.token, function () { return __awaiter(_this, void 0, void 0, function () {
                            return __generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0: return [4 /*yield*/, doFirstInstall(editor)];
                                    case 1:
                                        _a.sent();
                                        return [2 /*return*/];
                                }
                            });
                        }); })];
                case 6:
                    _a.sent();
                    return [3 /*break*/, 8];
                case 7:
                    ex_2 = _a.sent();
                    Sentry.captureException(ex_2);
                    return [3 /*break*/, 8];
                case 8: return [3 /*break*/, 11];
                case 9: return [4 /*yield*/, doFirstInstall(editor)];
                case 10:
                    _a.sent();
                    _a.label = 11;
                case 11: return [4 /*yield*/, getAllSearchBoxes(editor)];
                case 12:
                    allSearchBoxes = _a.sent();
                    if (!shouldReconnectSearchBoxes()) return [3 /*break*/, 14];
                    /**
                     * This is a hacky way to reconnect 'abandoned' SearchBox'es (probably added by copy-pasting).
                     * Investigate if it's still really needed.
                     * https://jira.wixpress.com/browse/SER-1310
                     */
                    return [4 /*yield*/, reconnectSearchBoxes(editor, allSearchBoxes)];
                case 13:
                    /**
                     * This is a hacky way to reconnect 'abandoned' SearchBox'es (probably added by copy-pasting).
                     * Investigate if it's still really needed.
                     * https://jira.wixpress.com/browse/SER-1310
                     */
                    _a.sent();
                    _a.label = 14;
                case 14: return [4 /*yield*/, Promise.all(allSearchBoxes.map(function (sb) { return patchInputFontProperty(editor, sb); }))];
                case 15:
                    _a.sent();
                    return [4 /*yield*/, editor.SDK.document.application.registerToCustomEvents(editor.token, {
                            eventTypes: [EventType.ComponentAddedToStage],
                        })];
                case 16:
                    _a.sent();
                    fedopsLogger.interactionEnded(Interactions.EditorReady);
                    addBreadcrumb('editorReadyHandler: finish');
                    return [2 /*return*/];
            }
        });
    });
});
export var onEvent = withErrorReporting(function eventHandler(_a) {
    var eventType = _a.eventType, eventPayload = _a.eventPayload;
    return __awaiter(this, void 0, void 0, function () {
        var _b;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    addBreadcrumb("eventHandler(eventType=\"" + eventType + "\"): start");
                    _b = eventType;
                    switch (_b) {
                        case EventType.ComponentAddedToStage: return [3 /*break*/, 1];
                    }
                    return [3 /*break*/, 3];
                case 1: return [4 /*yield*/, onComponentAddedToStage(editor, eventPayload)];
                case 2:
                    _c.sent();
                    return [3 /*break*/, 3];
                case 3:
                    addBreadcrumb("eventHandler(eventType=\"" + eventType + "\"): finish");
                    return [2 /*return*/];
            }
        });
    });
});
export var handleAction = withErrorReporting(function actionHandler(_a) {
    var type = _a.type;
    return __awaiter(this, void 0, void 0, function () {
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    addBreadcrumb("actionHandler(type=\"" + type + "\"): start");
                    if (!(type === 'removeApp')) return [3 /*break*/, 2];
                    return [4 /*yield*/, onRemoveApp(editor)];
                case 1:
                    _b.sent();
                    _b.label = 2;
                case 2:
                    addBreadcrumb("actionHandler(type=\"" + type + "\"): finish");
                    return [2 /*return*/];
            }
        });
    });
});
export var getAppManifest = function () {
    var _a;
    return Promise.resolve({
        controllersStageData: (_a = {},
            _a[ControllerType.SearchApp] = {
                default: {
                    visibility: 'NEVER',
                },
            },
            _a),
    });
};
function shouldReconnectSearchBoxes() {
    return !getIsInEditorX();
}
fedopsLogger.appLoaded();
addBreadcrumb('initSearchPlatform: finish');
