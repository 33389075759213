import { __awaiter, __generator } from "tslib";
import * as Sentry from '@sentry/browser';
import { getConnectedSearchBoxes } from '../getConnectedSearchBoxes';
import { addBreadcrumb } from '../sentry';
import { connectSearchBox } from '../searchAppController';
import { initSearchBoxSuggestions } from '../searchBox';
export function reconnectSearchBoxes(editor, allSearchBoxes) {
    return __awaiter(this, void 0, void 0, function () {
        var connectedSearchBoxes, unconnectedSearchBoxes;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    addBreadcrumb('reconnectSearchBoxes: start');
                    return [4 /*yield*/, getConnectedSearchBoxes(editor)];
                case 1:
                    connectedSearchBoxes = _a.sent();
                    if (!(allSearchBoxes.length !== connectedSearchBoxes.length)) return [3 /*break*/, 3];
                    Sentry.withScope(function (scope) {
                        scope.setTags({
                            searchBoxesCount: "" + allSearchBoxes.length,
                            searchBoxesConnectedCount: "" + connectedSearchBoxes.length,
                            searchBoxesUnconnectedCount: "" + (allSearchBoxes.length -
                                connectedSearchBoxes.length),
                        });
                        Sentry.captureMessage("There are more search boxes than connected search boxes");
                    });
                    unconnectedSearchBoxes = allSearchBoxes.filter(function (componentRef) { return !connectedSearchBoxes.includes(componentRef); });
                    return [4 /*yield*/, Promise.all(unconnectedSearchBoxes.map(function (componentRef) {
                            return connectSearchBox(editor, componentRef);
                        }))];
                case 2:
                    _a.sent();
                    connectedSearchBoxes.push.apply(connectedSearchBoxes, unconnectedSearchBoxes);
                    _a.label = 3;
                case 3: return [4 /*yield*/, Promise.all(connectedSearchBoxes.map(function (connectedSearchBoxRef) {
                        return initSearchBoxSuggestions(editor, connectedSearchBoxRef);
                    }))];
                case 4:
                    _a.sent();
                    addBreadcrumb('reconnectSearchBoxes: finish');
                    return [2 /*return*/];
            }
        });
    });
}
