import { __awaiter, __generator } from "tslib";
import { fedopsLogger, Interactions } from '../fedopsLogger';
import { onSearchBoxAdded } from '../onSearchBoxAdded';
import { isSearchBox } from '../searchBox';
import { addBreadcrumb } from '../sentry';
export function onComponentAddedToStage(editor, payload) {
    return __awaiter(this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    addBreadcrumb('onComponentAddedToStage: start');
                    // NOTE: uncomment this code when problem with controllers will be resolved
                    // payload.appDefinitionId === editor.appDefinitionId &&
                    return [4 /*yield*/, isSearchBox(editor, payload.compRef)];
                case 1:
                    if (!
                    // NOTE: uncomment this code when problem with controllers will be resolved
                    // payload.appDefinitionId === editor.appDefinitionId &&
                    _a.sent()) 
                    // NOTE: uncomment this code when problem with controllers will be resolved
                    // payload.appDefinitionId === editor.appDefinitionId &&
                    return [3 /*break*/, 3];
                    fedopsLogger.interactionStarted(Interactions.SearchBoxAdded);
                    return [4 /*yield*/, onSearchBoxAdded(editor, payload.compRef)];
                case 2:
                    _a.sent();
                    fedopsLogger.interactionEnded(Interactions.SearchBoxAdded);
                    _a.label = 3;
                case 3:
                    addBreadcrumb('onComponentAddedToStage: finish');
                    return [2 /*return*/];
            }
        });
    });
}
