import { __awaiter, __generator } from "tslib";
import { getControllerByType, getControllersByType, findControllerByType, } from '@wix/platform-editor-helpers';
import { getSearchAppControllerComponentDefinition } from '../componentDefinitions';
import { ControllerType, WixCodeRole, } from '../types';
import { addBreadcrumb } from '../sentry';
import { getIsInEditorX } from '../isInEditorX';
export function connectControllerWithSearchBox(editor, controllerRef, searchBoxRef) {
    return __awaiter(this, void 0, Promise, function () {
        var ex_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    addBreadcrumb('connectControllerWithSearchBox: start');
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, editor.SDK.controllers.connect(editor.token, {
                            controllerRef: controllerRef,
                            connectToRef: searchBoxRef,
                            role: WixCodeRole.SearchBox,
                        })];
                case 2:
                    _a.sent();
                    return [3 /*break*/, 4];
                case 3:
                    ex_1 = _a.sent();
                    console.error('error in editor.SDK.controllers.connect()', ex_1);
                    return [3 /*break*/, 4];
                case 4:
                    addBreadcrumb('connectControllerWithSearchBox: finish');
                    return [2 /*return*/];
            }
        });
    });
}
export function connectControllerWithSearchResults(editor, controllerRef, searchResultsRef) {
    return __awaiter(this, void 0, Promise, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    addBreadcrumb('connectControllerWithSearchResults: start');
                    return [4 /*yield*/, editor.SDK.controllers.connect(editor.token, {
                            controllerRef: controllerRef,
                            connectToRef: searchResultsRef,
                            role: WixCodeRole.SearchResults,
                        })];
                case 1:
                    _a.sent();
                    addBreadcrumb('connectControllerWithSearchResults: finish');
                    return [2 /*return*/];
            }
        });
    });
}
export function addSearchAppController(editor, pageRef) {
    return __awaiter(this, void 0, Promise, function () {
        var result;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    addBreadcrumb('addSearchAppController: start');
                    if (!!pageRef) return [3 /*break*/, 2];
                    return [4 /*yield*/, editor.SDK.siteSegments.getHeader()];
                case 1:
                    // NOTE: use header as master page ref, to add controller to all pages.
                    // we could not use direct `masterPage` ref, because it will brake logic with popups
                    pageRef = _a.sent();
                    _a.label = 2;
                case 2: return [4 /*yield*/, editor.SDK.components.add(editor.token, {
                        componentDefinition: getSearchAppControllerComponentDefinition({
                            appDefinitionId: editor.appDefinitionId,
                        }),
                        pageRef: pageRef,
                    })];
                case 3:
                    result = _a.sent();
                    addBreadcrumb('addSearchAppController: finish');
                    return [2 /*return*/, result];
            }
        });
    });
}
function getSearchAppControllerClassic(editor, pageRef, componentRef) {
    return getControllerByType(editor, ControllerType.SearchApp, pageRef);
}
function getSearchAppControllerEditorX(editor, pageRef, componentRef) {
    return __awaiter(this, void 0, Promise, function () {
        var connectableControllers, ex_2;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, editor.SDK.document.controllers.listConnectableControllers(editor.token, { componentRef: componentRef })];
                case 1:
                    connectableControllers = _a.sent();
                    return [2 /*return*/, connectableControllers.length
                            ? connectableControllers[0].controllerRef
                            : undefined];
                case 2:
                    ex_2 = _a.sent();
                    console.log('Error while calling SDK.document.controllers.listConnectableControllers', ex_2);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    });
}
export function getSearchAppControllers(editor) {
    return __awaiter(this, void 0, void 0, function () {
        var results;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    addBreadcrumb('getSearchAppControllers: start');
                    return [4 /*yield*/, getControllersByType(editor, ControllerType.SearchApp)];
                case 1:
                    results = _a.sent();
                    addBreadcrumb('getSearchAppControllers: finish');
                    return [2 /*return*/, results];
            }
        });
    });
}
export function isComponentConnected(editor, componentRef) {
    return __awaiter(this, void 0, Promise, function () {
        var connections, controllers, controllerRef;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    addBreadcrumb('isComponentConnected: start');
                    return [4 /*yield*/, editor.SDK.document.controllers.listConnections(editor.token, { componentRef: componentRef })];
                case 1:
                    connections = _a.sent();
                    controllers = connections.map(function (connection) { return connection.controllerRef; });
                    return [4 /*yield*/, findControllerByType(editor, controllers, ControllerType.SearchApp)];
                case 2:
                    controllerRef = _a.sent();
                    addBreadcrumb('isComponentConnected: finish');
                    return [2 /*return*/, !!controllerRef];
            }
        });
    });
}
export function connectSearchBox(editor, componentRef) {
    return __awaiter(this, void 0, void 0, function () {
        var pageRef, getSearchAppController, controllerRef;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    addBreadcrumb('connectSearchBox: start');
                    return [4 /*yield*/, editor.SDK.components.getPage(editor.token, {
                            componentRef: componentRef,
                        })];
                case 1:
                    pageRef = _a.sent();
                    getSearchAppController = getIsInEditorX()
                        ? getSearchAppControllerEditorX
                        : getSearchAppControllerClassic;
                    return [4 /*yield*/, getSearchAppController(editor, pageRef, componentRef)];
                case 2:
                    controllerRef = _a.sent();
                    if (!!controllerRef) return [3 /*break*/, 4];
                    return [4 /*yield*/, addSearchAppController(editor, pageRef)];
                case 3:
                    controllerRef = _a.sent();
                    _a.label = 4;
                case 4: return [4 /*yield*/, connectControllerWithSearchBox(editor, controllerRef, componentRef)];
                case 5:
                    _a.sent();
                    addBreadcrumb('connectSearchBox: finish');
                    return [2 /*return*/];
            }
        });
    });
}
