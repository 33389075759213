import { __awaiter, __generator } from "tslib";
import * as Sentry from '@sentry/browser';
import { removeController, removeControllerConnectedComponents, } from '@wix/platform-editor-helpers';
import { fedopsLogger, Interactions } from '../fedopsLogger';
import { getSearchAppControllers } from '../searchAppController';
import { addBreadcrumb } from '../sentry';
export function onRemoveApp(editor) {
    return __awaiter(this, void 0, void 0, function () {
        var controllerRefs, _i, controllerRefs_1, controllerRef, ex_1, ex_2;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    addBreadcrumb('onRemoveApp: start');
                    fedopsLogger.interactionStarted(Interactions.RemoveApp);
                    return [4 /*yield*/, getSearchAppControllers(editor)];
                case 1:
                    controllerRefs = _a.sent();
                    _i = 0, controllerRefs_1 = controllerRefs;
                    _a.label = 2;
                case 2:
                    if (!(_i < controllerRefs_1.length)) return [3 /*break*/, 10];
                    controllerRef = controllerRefs_1[_i];
                    _a.label = 3;
                case 3:
                    _a.trys.push([3, 5, , 6]);
                    addBreadcrumb('removeControllerConnectedComponents: before');
                    return [4 /*yield*/, removeControllerConnectedComponents(editor, controllerRef)];
                case 4:
                    _a.sent();
                    addBreadcrumb('removeControllerConnectedComponents: after');
                    return [3 /*break*/, 6];
                case 5:
                    ex_1 = _a.sent();
                    addBreadcrumb('removeControllerConnectedComponents: failed');
                    Sentry.captureException(ex_1);
                    return [3 /*break*/, 6];
                case 6:
                    _a.trys.push([6, 8, , 9]);
                    addBreadcrumb('removeController: before');
                    return [4 /*yield*/, removeController(editor, controllerRef)];
                case 7:
                    _a.sent();
                    addBreadcrumb('removeController: after');
                    return [3 /*break*/, 9];
                case 8:
                    ex_2 = _a.sent();
                    addBreadcrumb('removeController: failed');
                    Sentry.captureException(ex_2);
                    return [3 /*break*/, 9];
                case 9:
                    _i++;
                    return [3 /*break*/, 2];
                case 10: return [4 /*yield*/, saveEditor(editor)];
                case 11:
                    _a.sent();
                    fedopsLogger.interactionEnded(Interactions.RemoveApp);
                    addBreadcrumb('onRemoveApp: finish');
                    return [2 /*return*/];
            }
        });
    });
}
function saveEditor(editor) {
    return __awaiter(this, void 0, void 0, function () {
        var ex_3;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    addBreadcrumb('saveEditor: start');
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, editor.SDK.editor.save()];
                case 2:
                    _a.sent();
                    return [3 /*break*/, 4];
                case 3:
                    ex_3 = _a.sent();
                    addBreadcrumb('saveEditor: failed');
                    Sentry.captureException(ex_3);
                    return [3 /*break*/, 4];
                case 4:
                    addBreadcrumb('saveEditor: finish');
                    return [2 /*return*/];
            }
        });
    });
}
