import { __awaiter, __generator } from "tslib";
import { connectSearchBox, isComponentConnected } from '../searchAppController';
import { initSearchBoxSuggestions } from '../searchBox';
import { addBreadcrumb } from '../sentry';
export function onSearchBoxAdded(editor, componentRef) {
    return __awaiter(this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    addBreadcrumb('onSearchBoxAdded: start');
                    return [4 /*yield*/, isComponentConnected(editor, componentRef)];
                case 1:
                    if (!!(_a.sent())) return [3 /*break*/, 3];
                    return [4 /*yield*/, connectSearchBox(editor, componentRef)];
                case 2:
                    _a.sent();
                    _a.label = 3;
                case 3: return [4 /*yield*/, initSearchBoxSuggestions(editor, componentRef)];
                case 4:
                    _a.sent();
                    addBreadcrumb('onSearchBoxAdded: finish');
                    return [2 /*return*/];
            }
        });
    });
}
