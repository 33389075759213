import { __assign, __awaiter, __generator } from "tslib";
import * as Sentry from '@sentry/browser';
import { ignoreErrors } from '@wix/search-shared-libs';
import { getIsInEditorX } from './isInEditorX';
export var SENTRY_DSN = 'https://acda05ef47624af292111a305c52cd50@sentry.wixpress.com/209';
// TODO: review when https://github.com/wix/yoshi/pull/1496 resolved
var ARTIFACT_VERSION = window.__CI_APP_VERSION__;
export function initErrorLogging() {
    Sentry.init({
        ignoreErrors: ignoreErrors,
        dsn: SENTRY_DSN,
        release: ARTIFACT_VERSION,
        enabled: process.env.NODE_ENV === 'production',
        // NOTE: We don't need to report all `window.onerror` events. Manual capturing  is enough
        // https://docs.sentry.io/platforms/javascript/#automatically-capturing-errors-with-promises
        integrations: [
            new Sentry.Integrations.GlobalHandlers({
                onunhandledrejection: false,
                onerror: false,
            }),
        ],
    });
}
export function withErrorReporting(fn) {
    var _this = this;
    return function () {
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        return __awaiter(_this, void 0, void 0, function () {
            var error_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, fn.apply(void 0, args)];
                    case 1:
                        _a.sent();
                        return [3 /*break*/, 3];
                    case 2:
                        error_1 = _a.sent();
                        Sentry.withScope(function (scope) {
                            scope.setExtra('arguments', args);
                            Sentry.captureException(error_1);
                        });
                        throw error_1;
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
}
export function addBreadcrumb(message, opts) {
    if (opts === void 0) { opts = {}; }
    Sentry.addBreadcrumb(__assign({ message: message, level: Sentry.Severity.Info }, opts));
}
function parseJWT(jwt) {
    return JSON.parse(atob(jwt.split('.')[1]));
}
export function setSentryContext(_a) {
    var firstInstall = _a.firstInstall, initialAppData = _a.initialAppData;
    var instanceData = parseJWT(initialAppData.instance);
    Sentry.setUser({
        id: instanceData.uid,
    });
    Sentry.setTags({
        firstInstall: String(firstInstall),
        msid: instanceData.metaSiteId,
        isInEditorX: getIsInEditorX(),
    });
    Sentry.setExtras(__assign(__assign({}, initialAppData), { instanceData: instanceData }));
}
